<template>
  <div>
    <associatedOption
      :fieldAttributes="fieldAttributes"
      type="tagCombo"
      :value="selectedVal"
      :fieldAttrInput="{
        ...fieldAttributes.fieldAttrInput,
        multiple: true,
        chips: true,
        class: 'addable tagContainerAdjust',
        id: id,
      }"
      :templateContent="result"
      :name="field"
      @input="updateValue(...arguments)"
    >
      <template v-slot:selection="slotProps">
        <v-chip
          label
          small
          v-bind="chipBind"
          @click:close="removeValue(slotProps.item)"
        >
          <span>{{ slotProps.item.text }}</span>
        </v-chip>
        <input
          type="hidden"
          :name="field + '[]'"
          :value="slotProps.item.value"
        />
      </template>
    </associatedOption>
  </div>
</template>
<script>
import { getFieldId } from "@/js/helper.js";
import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  components: { associatedOption },
  props: ["result", "field", "fieldAttributes", "value"],
  // data: function () {
  //   return {
  //     selectedVal: this.value,
  //   };
  // },
  computed: {
    selectedVal() {
      return this.value;
    },
    id() {
      return getFieldId(
        this.fieldAttributes.fieldAttrInput,
        this.result,
        this.field
      );
    },
    chipBind() {
      let chipBind = {};
      //console.log(chipBind);
      if (typeof this.fieldAttributes.fieldAttrInput.readonly == "undefined") {
        chipBind = { close: true };
      }
      return chipBind;
    },
  },
  methods: {
    removeValue(item) {
      if (typeof this.fieldAttributes.fieldAttrInput.readonly == "undefined") {
        //console.log(item.value, this.selectedVal);
        this.selectedVal.splice(this.selectedVal.indexOf(item.value), 1);
      }
    },
    updateValue(value) {
      let filtered = value.filter((e) => String(e).trim()); //No more empty Tag!
     // this.selectedVal = filtered;
      this.$emit("input", filtered);
    },
  },
};
</script>