<template>
  <div>
    <categoryTagInput
      :result="result"
      :field="field"
      :fieldAttributes="fieldAttributes"
      v-on="$listeners"
      :value="value"
    ></categoryTagInput>
  </div>
</template>
<script>
import categoryTagInput from "@/components/dynamic/categoryTagInput.vue";
export default {
  props: ["result", "field", "fieldAttributes", "value"],
  components: { categoryTagInput },
};
</script>